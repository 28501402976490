const rgb = (hex: string) => hex.match(/.{1,2}/g).map((e: string) => parseInt(e, 16));

function parashiftBlue(opacity = .4) {
  // FD: TODO: Add white-label colors to body
  // const color = getComputedStyle(document.body).getPropertyValue('--primary-color').replace('#', '');
  const color = '4db6ce';
  const r = rgb(color);
  return `rgba(${r[0]}, ${r[1]}, ${r[2]}, ${opacity})`;
}

function parashiftBlueLight(opacity = .4) {
  // const color = getComputedStyle(document.body).getPropertyValue('--nav-color');
  const color = 'B8E2EB';
  const [r, g, b] = rgb(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function parashiftSecondary(opacity = .4) {
  // const color = getComputedStyle(document.body).getPropertyValue('--secondary-color');
  const color = '095769';
  const [r, g, b] = rgb(color);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export { parashiftBlue, parashiftBlueLight, parashiftSecondary };
