import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Coordinates } from '@parashift/shared/models';

export interface ConnectionField {
  element: HTMLElement;
  valueOnPage: ValueOnPage;
}

export interface ValueOnPage {
  value: any;
  coordinates: Coordinates;
  page_number: number;
}

@Injectable({
  providedIn: 'root'
})
export class FieldTokenConnectorService {
  private _enabled = new BehaviorSubject<boolean>(false);
  enabled$ = this._enabled.asObservable();

  private _fieldBoundingClientRect = new BehaviorSubject<DOMRect>(undefined);
  fieldBoundingClientRect$ = this._fieldBoundingClientRect.asObservable();

  private _tokenBoundingClientRect = new BehaviorSubject<DOMRect>(undefined);
  tokenBoundingClientRect$ = this._tokenBoundingClientRect.asObservable();

  private _previewResized = new Subject<void>();
  previewResized$ = this._previewResized.asObservable();

  private _scrolled = new Subject<void>();
  scrolled$ = this._scrolled.asObservable();

  tokenClippingMask: DOMRect = undefined;

  // This getter is needed to refresh formClippingMask on each FieldTokenConnectorComponent.clip() call.
  // Initial form bounding client rect changes due to form being filled with data asynchronously.
  get formClippingMask(): DOMRect { return this.formContainerElement.getBoundingClientRect(); }

  field: ConnectionField = undefined;
  formContainerElement: HTMLElement = undefined;

  private previousField: ConnectionField;

  enable(): Promise<void> {
    // This setTimeout is mandatory to avoid ExpressionChangedAfterItHasBeenCheckedError in app.component <app-token-connector> line.
    // Due to usage of fieldTokenConnectorService.enabled$ on app.component level and updating it in lower-level extraction.component,
    // the variable gets updated more than once in single change detection cycle, resulting in mentioned error.
    // setTimeout pushes this update to very bottom of event loop and prevents the error.
    // reference: https://stackoverflow.com/questions/48870648/angular-expressionchangedafterithasbeencheckederror-in-same-component
    return new Promise<void>(resolve => {
      setTimeout(() => {
        this._enabled.next(true);
        resolve();
      });
    });
  }

  disable(): void {
    this.field = undefined;
    this.formContainerElement = undefined;
    this.tokenClippingMask = undefined;
    this._enabled.next(false);
    this._fieldBoundingClientRect.next(undefined);
    this._tokenBoundingClientRect.next(undefined);
  }

  notifyPreviewResize(): void {
    this._previewResized.next();
  }

  notifyScroll(): void {
    this._scrolled.next();
  }

  setActiveTokenBoundingClientRect(rect: DOMRect): void {
    if (rect && (rect.width === 0 || rect.height === 0)) {
      this._tokenBoundingClientRect.next(undefined);
      return;
    }
    this._tokenBoundingClientRect.next(rect);
  }

  setField(element: HTMLElement, valueOnPage?: ValueOnPage): void {
    if (!element) {
      this.field = this.previousField = undefined;
      this.updateFieldBoundingRect();
      return;
    }

    this.previousField = this.field;

    if (!valueOnPage) {
      throw new Error('Value on page not provided.');
    }

    this.field = {
      element,
      valueOnPage
    };

    this.updateFieldBoundingRect();
  }

  rollbackField(): void {
    if (!this.previousField) {
      this.setField(undefined);
      return;
    }

    this.setField(this.previousField.element, {
      value: this.previousField.valueOnPage.value,
      coordinates: this.previousField.valueOnPage.coordinates,
      page_number: this.previousField.valueOnPage.page_number
    });

    this.previousField = undefined;
  }

  updateFieldBoundingRect(): void {
    if (this.field) {
      this._fieldBoundingClientRect.next(this.field.element.getBoundingClientRect() as DOMRect);
    } else {
      this._fieldBoundingClientRect.next(undefined);
    }
  }
}
